html {

    font-size: 20px;
    font-size: calc(20 * 100 / 960)vw;
    font-weight: 300;
    font-family: "Overpass", sans-serif;
    line-height: 1.2;
    background: white;
    color: rgb(35, 31, 32);
    box-sizing: border-box;

    -webkit-font-smoothing: antialiased;

    @media (resolution >= 192dpi) {

        -webkit-font-smoothing: antialiased;

    }

    /* @media (width > 1440px) {

        font-size: 22px;

    } */
}

*,
*:before,
*:after {

    box-sizing: inherit;

}

body {

    padding: 4rem 2.5rem 2.5rem;
    margin: 0 auto;
    position: relative;

    -webkit-overflow-scrolling: touch;

    background-color: white;

    @media (width <= 576px) {

        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 1rem;

    }

    img {

        display: block;
        width: 100%;
        max-width: 100%;
        object-fit: contain;

    }

    h3 {

        font-size: 1rem;
        font-family: Overpass, sans-serif;
        font-weight: 900;
        text-transform: uppercase;
        line-height: 1.2;
        letter-spacing: 0.05em;
        max-width: 18rem;
        padding-right: 6rem;
        padding-bottom: 0.5rem;
        color: rgb(29, 54, 255);

        background-image: linear-gradient(transparent, transparent),
                          linear-gradient(rgb(29, 54, 255), rgb(29, 54, 255));
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: 100% 0.1em;

        @media (width <= 768px) {

            font-size: 1.2rem;
            max-width: 22rem;

        }

        @media (width <= 576px) {

            font-size: 1.4rem;
            max-width: 26rem;

        }

        @media (width <= 384px) {

            font-size: 1.6rem;
            max-width: 30rem;

        }
    }
}
blockquote {

    display: flex;
    justify-content: center;
    margin: 8rem auto;
    flex-direction: column;
    max-width: 50%;
    font-family: Overpass,sans-serif;
    font-size: 2rem;
    font-weight: 100;
    line-height: 1.3;

    @media (width <= 768px) {

        margin: 4rem auto;

    }

    @media (width <= 560px) {

        max-width: 100%;

    }

    p {

        &:before, 
        &:after {

            display: inline-block;
            margin-top: -1em;
            margin-bottom: -1em;
            content: "";
            color: rgb(30, 54, 255);;
            font-size: 2.33rem;

        }

        &:before {

            content: "\00ab";
            margin-right: 0.2em;

        }

        &:after { 

            content: "\00bb";
            margin-left: 0.2em;

        }
    }

    cite {

        font-family: Playfair Display, serif;
        font-size: 1rem;
        display: flex;
        align-items: center;
        font-style: italic;
        color: rgb(30, 54, 255);
        margin: 1.25rem 0;
        position: relative;

        &:before {

            content: "";
            display: block;
            width: 3rem;
            margin-right: 2rem;
            height: 0.1rem;
            background-color: rgb(30, 54, 255);

        }
    }
}
.clients[data-grid] {

    margin: 2rem 0;

}

.clients[data-grid="3"] {

    @media (width < 720px) {

        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;

        li {

            a:not([data-client]) {


                height: 100%;
            }
        }

        > *:nth-child(2n + 2) {

            transition-delay: 0.15s;

        }
    }

    @media (width >= 720px) and (width <= 1600px) {

        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: auto;

        @for $nth from 2 to 3 {

            > *:nth-child(3n + $nth) {

                transition-delay: calc(($nth - 1) * 0.15s);

            }
        }
    }

   @media (width > 1600px) {

        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: auto;

        @for $nth from 1 to 4 {

            > *:nth-child(4n + $nth) {

                transition-delay: calc(($nth - 1) * 0.15s);

            }
        }
    }
}

.clients[data-grid="6"] {

    @media (width < 576px) {

        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: auto;

        @for $nth from 2 to 3 {

            > *:nth-child(3n + $nth) {

                transition-delay: calc(($nth - 1) * 0.1s);

            }
        }
    }

    @media (width >= 576px) and (width < 768px) {

        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: auto;

        @for $nth from 2 to 5 {

            > *:nth-child(5n + $nth) {

                transition-delay: calc(($nth - 1) * 0.1s);

            }
        }
    }

    @media (width >= 768px) {

        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: auto;

        @for $nth from 2 to 6 {

            > *:nth-child(6n + $nth) {

                transition-delay: calc(($nth - 1) * 0.1s);

            }
        }
    }
}

.client-main {

    img + img,
    img + .client-project  {

        padding-top: 1.2rem;

    }

}

.clients {

    @media (width > 1600px) {

        .staircase {

            font-size: .7rem;

        }

    }

    li,
    a {

        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

    }

    a {

        text-decoration: none;

    }

    .missing-logo:hover,
    .see-more:hover {

        span {

            background-size: 100% 0.05em;

        }
    }

    svg {

        display: block;
        width: 100%;

    }

    .missing-logo,
    .see-more {

        height: 100%;

        span {

            text-transform: uppercase;
            text-decoration: none;
            font-weight: 300;
            color: #1d36ff;
            background-image: linear-gradient(transparent, transparent),                              linear-gradient(#1d36ff, #1d36ff);
            background-size: 100% 0.05em, 0 0.05em;
            background-repeat: no-repeat;
            background-position: bottom, 0 100%;
            transition: background-size 0.15s ease-out;

        }
    }

    .staircase {

        [data-step]:before {

            color: white;

        }

        [data-step]:after {

            color: #05f398;

        }

        small {

            position: relative;
            padding-top: 0.5rem;

            &:before {

                content: "";
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: 2rem;
                height: 1px;
                background: #05f398;

            }
        }
    }

    &.inverted {

        a {

            background: #231F20;

        }

        svg [fill^="#"] {

            fill: white !important;

        }
    }

    &[data-grid-square] {

        li{
            min-height: 10rem;
        }

        li,
        a {

            align-items: stretch;

        }
    }
}

.clients {

    a {

        &[data-client] {

            position: relative;
            overflow: hidden;

            &:before {

                content: "";
                z-index: 1;
                height: 100%;
                position: absolute;
                bottom: -100%;
                width: 100%;
                transform-origin: bottom;
                transition: all 0.8s cubic-bezier(0.86, 0, 0.07, 1);

            }

            &:hover:before{

                height: 100%;
                bottom: 0;
                transition: all 0.8s cubic-bezier(0.86, 0, 0.07, 1);

            }

            svg {

                position: relative;
                z-index: 2;

            }
        }
    }
}
.client-hero {

    display: flex;
    align-items: center;
    width: 100%;
    height: calc(100vh - 9.5rem);
    max-height: 30rem;
    overflow: hidden;

    .marquee {

        flex: 1;

        &:hover {

            .marquee-content:before {

                opacity: 0;

            }

        }

        .marquee-content:before {

            opacity: 0;

        }

    }
}
.client-information {

    font-family: Overpass, sans-serif;
    font-size: 1.5rem;
    font-weight: 300;
    color: rgb(35 ,31, 32);
    margin: 4rem 8rem;

    @media (width <= 800px) {

        margin: 4rem;

    }

    @media (width <= 768px) {

        font-size: 2rem;

    }

    &.client-intro {

        display: grid;
        grid-template-columns: 1fr 12rem;
        grid-template-rows: 1fr;
        grid-gap: 2rem;
        /* For scrollIntoView, change margin for padding */
        margin: 0;
        padding: 2.5rem 0;

        @media (width <= 576px) {

            grid-template-columns: 1fr;

        }
    }

    div :first-child {

        margin-top: 0;

    }

    div :last-child {

        margin-bottom: 0;

    }

    .client-tags {

        font-size: 1rem;
        list-style: none;
        margin: 0;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: min-content;
        grid-gap: 0.5rem;
        align-self: flex-start;

        @media (width <= 768px) {

            font-size: 1.25rem;

        }

        @media (width <= 576px) {

            display: flex;
            max-width: 30rem;
            flex-flow: row wrap;
            margin: 0 auto;
            justify-content: center;

            li {

                margin: 0.5rem 1.5rem;

            }

        }
    }
}
.client-project {

    display: grid;

    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: min-content;

    + .client-project,
    + img {

        padding-top: 1.2rem;

    }

    &.normal-disposition {

        &.padding {

            &.two-element {

                img {

                    &:first-child {

                        padding-right: 0.6rem;
                        padding-bottom: 0;

                    }

                    &:last-child {

                        padding-left: 0.6rem;
                        padding-top: 0;
                        padding-bottom: 0;

                    }

                }

            }

            img{

                &:first-child {

                    padding-right: 0.6rem;
                    padding-bottom: 0.6rem;

                }

                &:nth-child(2) {

                    padding-left: 0.6rem;
                    padding-bottom: 0.6rem;

                }

                &:nth-child(3) {

                    padding-right: 0.6rem;
                    padding-top: 0.6rem;

                }

                &:last-child {

                    padding-left: 0.6rem;
                    padding-top: 0.6rem;

                }

            }

        }

        img {

            object-fit: cover;

        }

    }

    + .client-project.normal-disposition {

        padding-top: 1.2rem;

    }

    img + img {

        padding-top:  0;

    }

    &:not(.normal-disposition) {

        grid-template-areas: "primary primary"
                             "secondary tertiary";

        img:nth-child(1) {

            grid-area: primary;
            object-fit: cover;

        }

        img:nth-child(2) {

            grid-area: secondary;
            object-fit: fill;

        }

        img:nth-child(3) {

            grid-area: tertiary;

        }
    }

    a {

        grid-area: tertiary;
        display: flex;
        justify-content: center;
        text-align: center;
        font-family: Overpass,sans-serif;
        font-weight: 900;
        font-size: 1.2rem;
        color: white;
        position: relative;
        align-content: center;
        align-items: center;
        text-decoration: none;
        z-index: 1;

        @media (width <= 800px) and (width > 480px){

            font-size: 1rem;

        }

        @media (width <= 480px) {

            font-size: 1.2rem;

        }

        @supports(transform-box: fill-box) {

            &:hover {

                svg {

                    transform: rotate(30deg) translate3d(-50%, -50%, 0);

                }
            }
        }

        svg {

            width: 5.5rem;
            position: absolute;
            pointer-events: none;
            top: 50%;
            left: 50%;
            opacity: 0.75;
            transform: translate3d(-50%, -50%, 0);
            z-index: -1;

            @supports(transform-box: fill-box) {

                transform-box: fill-box;
                transform-origin: top left;
                transition: transform 1s cubic-bezier(0.455, 0.03, 0.515, 0.955);

            }
        }
    }
}
.components--alerts--warning {
    width: 100%;
    background-color: orange;
    color: white;
    font-weight: bold;
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    height: fit-content;
    padding: 8px 0;

    p {
        margin: 0;
        font-size: 16px;
    }
}
.contact-address {

    height: 19.5rem;
    background-color: rgb(30, 54, 255);
    padding: 3.5rem;
    margin-top: 2rem;
    font-size: 1.5rem;
    color: white;
    display: flex;
    flex-direction: column;

    p{
        margin: 0;
        span {
            display: block;
        }
    }

    div {

        address {

            font-style: normal;

        }

        a {

            display: inline-block;
            color: #fff;
            padding-bottom: 0.1em;
            text-decoration: none;
            transition: all 0.15s ease-out;

            background-image: linear-gradient(transparent, transparent),
                                    linear-gradient(white, white);
            background-size: 100% 0.05em, 0 0.05em;
            background-repeat: no-repeat;
            background-position: bottom, 0 100%;

            &:hover {

                background-size: 100% 0.05em;

            }

            span {

                display: block;

            }
        }

        [itemprop="postalCode"] {

            display: inline-block;

            &:before {

                content: "";
                height: 0.03em;
                width: 1em;
                display: inline-block;
                vertical-align: middle;
                background: #fff;
                margin: 0 0.5em;

            }
        }
    }

    nav {

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;
        grid-gap: 1rem;
        margin-right: 1rem;
        margin-top: auto;
        align-self: flex-end;

        @media (width <= 640px) {

            grid-gap: 1.5rem;

        }

        a {

            display: block;
            width: 2rem;
            align-self: center;

            @media (width <= 640px) and (width > 480px) {

                width: 2.5rem;

            }
            
            @media (width <= 480px) {

                width: 3.5rem;

            }

            &:hover {

                svg {

                    rect {

                        transform: scale(1, 1);

                    }
                }
            }
        }

        svg {

            width: 100%;
            display: block;
            transition: transform 0.3s ease-in-out;

            path {

                stroke: rgb(250, 250, 250);

            }
            
            rect {

                transform-origin: center bottom;
                transform: scale(1, 0);
                fill: rgb(250, 250, 250);
                transition: all 0.3s ease-out;

            }
        }
    }
}

.contact-google-maps {

    height: 19.5rem;
    margin-bottom: 4rem;

    img[src*="maps.gstatic"],
    .gm-style-cc {

        opacity: 0;

    }
}
.section footer {

    height: 14rem;

    @media (width <= 620px) {

        height: 27rem;
        
    }

    @media (width <= 425px) {

        height: 25rem;
        
    }

}
.footer-global {

    font-family: Overpass, sans-serif;
    font-weight: 100;
    display: flex;
    flex-flow: row;
    align-items: flex-start;
    margin-top: 2.5rem;

    @media (width <= 640px) {

        display: grid;
        grid-template-areas: "description address"
                             "social social";
        grid-gap: 1rem;
        grid-template-columns: repeat(2, 1fr);
        margin-bottom: 3rem;

    }

    @media (width <= 576px) {

        font-size: 1.3rem;

    }

    @media (width <= 384px) {

        font-size: 1.5rem;

    }

    .footer-description {

        @media (width <= 640px) {

            grid-area: description;

        }

        p {

            margin-top: 0;

        }

        small {

            font-size: 0.6em;

            -webkit-font-smoothing: subpixel-antialiased;

            a {

                color: rgb(30, 54, 255);
                display: flex;
                align-items: center;
                text-decoration: none;

                @media (width <= 480px) {

                    font-size:      1.5rem;
                    padding-bottom: 0.6rem;

                }

                &:before {

                    content: "";
                    width: 3rem;
                    height: 0.5px;
                    margin-right: 1rem;
                    display: block;
                    background-color: rgb(30, 54, 255);

                }
            }
        }
    }
    
    .footer-address {

        font-style: normal;
        margin: 0 auto;

        @media (width <= 640px) {

            grid-area: address;
            margin: 0;

        }

        address {

            font-style: normal;

            [itemprop="postalCode"]:before {

                content: "";
                width: 1.3em;
                margin: 0 0.4em;
                height: 1px;
                display: inline-block;
                vertical-align: middle;
                background-color: rgb(35, 31, 32);

                @media (width <= 640px) {

                    width: 1em;
                    margin: 0 1em;

                }
            }
        }

        a {

            color: rgb(35, 31, 32);
            text-decoration: none;
            transition: all 0.15s ease-out;

            background-image: linear-gradient(transparent, transparent),
                              linear-gradient(rgb(35, 31, 32), rgb(35, 31, 32));
            background-size: 100% 0.05em, 0 0.05em;
            background-repeat: no-repeat;
            background-position: bottom, 0 100%;
            display: inline-block;
            width: auto;

            &:hover {

                background-size: 100% 0.05em;

            }
        }
    }

    .footer-social {

        width: 2rem;
        display: grid;
        grid-gap: 0.5rem;
        grid-template-columns: auto;
        grid-template-rows: auto;

        @media (width <= 640px) {

            grid-area: social;
            width: auto;
            max-height: 100%;
            margin: 1rem 0;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: 1fr;
            align-self: center;
            justify-self: center;

            /* Edge quirks, instead of align-items: center; */
            > * {

                align-self: center;

            }
        }

        @media (width <= 640px) and (width > 480px) { 

            grid-gap: 2rem;

        }

        @media (width <= 480px) {

            grid-gap: 3rem;

        }

        a {

            display: block;
            width: 100%;

            @media (width <= 640px) and (width > 480px) {

                width: 2.5rem;

            }
            
            @media (width <= 480px) {

                width: 3.5rem;

            }

            &:hover {

                svg {

                    rect {

                        transform: scale(1, 1);

                    }
                }

            }
        }

        svg {

            width: 100%;
            display: block;
            transition: transform 0.3s ease-in-out;
            
            rect {

                transform-origin: center bottom;
                transform: scale(1, 0);
                fill: rgb(29, 54, 255);
                transition: all 0.3s ease-out;

            }
        }
    }
}
.footer-portal[data-grid] {

    margin: 2.5rem 0;

}

/* .footer-portal {

    a {

        &:nth-child(2) {

            background: rgb(11, 27, 120);
            color: white;

            small {

                color: rgb(5, 243, 152);

            }
        }

        &:hover {

            &:nth-child(2) {

                strong {

                    color: rgb(5, 243, 152);

                }

                small {

                    color: white;

                }
            }

            .logo {

                rect:nth-of-type(2) {

                    transform: scale(1);

                }
            }

            small {

                background-size: 100% 0.07em;

            }
        }
    }
} */
.general {

    .swiper-inner{

        &[data-background="cominar"] {

            background: rgb(237, 5, 119);

        }

        justify-content: center;
        align-items: center;
        height: 28.6rem;
        margin-bottom: 2.5rem;
        overflow: hidden;

        .marquee {

            mix-blend-mode: initial;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            bottom: initial;

            &:hover {

                .marquee-content {

                    &::before {

                        opacity: 0;

                    }

                }

            }

        }

    }

    p {

        font-family: Overpass, sans-serif;
        font-size:   1.5rem;
        font-weight: 300;
        line-height: 1.85rem;
        color:       #231f20;

        span {

            display: block;

            &.no-break {

                white-space: nowrap;
                display:     inline-block;

            }

        }

        .red {

            color: #ff1f20;

        }

        sup {

            font-size:     1rem;
            padding-right: 0.3rem;
            
        }

    }

    ul {

        list-style: none;

        li {

            font-size: 1rem;
            font-weight: 300;
            font-family: Overpass, sans-serif;
            line-height: 1.85rem;
            color:       #231f20;

        }

    }

    .col2 {

        display: grid;
        grid-template-columns: 69% 31%;
        grid-template-rows: auto;
        width: 100%;
        margin-bottom: 3.6rem;

        ul {

            @media (width <= 480px) {

                padding: 0;
                margin: 0;

            }

        }

        p,
        ul {

            margin-top: 0;

        }

        p {

            padding-right: 1rem;

            @media (width <= 480px) {

                padding-right: 1.8rem;

            }

        }

    }

    .client-project {

        img + img {

            margin-top: 0;
        }

    }

    img + img,
    img + .footer-portal {

        margin-top: 1.2rem;

    }

}
[data-grid] {

    width: 100%;
    list-style: none;
    margin: 0 auto;
    padding: 0;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(3, 1fr);
    -ms-grid-columns: 1fr 1fr 1fr;             /* ie11 */

    > * {

        display: flex;

        a {

            width: 100%;
            display: flex;
            align-items: center;
            flex: 1;

        }
    }

    @for $i from 2 to 6 {

        &[data-grid="$i"] {
            
            grid-template-columns: repeat($i, 1fr);
            grid-template-rows:    auto;

        }
    }

    &[data-grid-margin] {

        grid-gap: 1rem;

    }

    &[data-grid-square] {

        > * {

            &:before {

                content: "";
                float: left;
                padding-top: 100%;

            }
        }
    }
}
.header-global {

    display: flex;
    position: fixed;
    width: 100vw;
    z-index: 3;
    padding: 2.5rem 2.5rem 1.25rem 2.5rem;
    top: 0;
    left: 0;
    background: white;
    align-items: flex-end;
    justify-content: space-between;
    transition: opacity 0.2s ease-out,
                transform 0.3s ease-out;
    background-color: white;

    @media (width <= 576px) {

        padding: 1rem;
        align-items: center;

    }

    [data-concealed] & {

        transform: translateY(-100%);

        > * {

            opacity: 0;

        }
    }

    > * {

        transition: opacity 0.3s ease-out;
        opacity: 1;

    }

    h1 {

        width: 8rem;
        margin: 0;

        @media (width <= 768px) {

            width: 10rem;

        }

        @media (width <= 576px) {

            width: 12rem;

        }

        @media (width <= 384px) {

            width: 14rem;

        }

        a {

            display: flex;
            align-items: center;

        }

        svg {

            width: 100%;

        }
    }

    button {

        display: block;
        overflow: hidden;
        width: 2.25rem;
        height: 2.25rem;
        cursor: pointer;
        position: relative;
        background: 0;
        padding: 0;
        border: 0;
        z-index: 5;

        @media (width > 768px) {

            display: none;
            pointer-events: none;

        }

        @media (width <= 576px) {

            width: 2.75rem;
            height: 2.75rem;

        }

        @media (width <= 384px) {

            width: 3.25rem;
            height: 3.25rem;

        }

        svg {

            stroke: rgb(35, 31, 32);
            overflow: visible;

            line {

                opacity: 1;
                transform: none;
                transition: all 0.3s ease-out;

            }

            [data-navigation-open] & {

                line {

                    stroke: white;
                    stroke-width: 2px;
                    transform-origin: center;

                    &:nth-of-type(1) {

                        transform: rotate(45deg) translateY(25%);

                    }

                    &:nth-of-type(2) {

                        opacity: 0;

                    }

                    &:nth-of-type(3) {

                        transform: rotate(-45deg) translateY(-25%);

                    }
                }
            }
        }
    }

    nav {

        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: repeat(5, auto);
        grid-gap: 2rem;
        font-weight: 400;
        font-family: Overpass, sans-serif;

        @media (width <= 768px) {

            display: flex;
            flex-flow: column;
            justify-content: flex-start;
            position: fixed;
            z-index: 3;
            right: 0;
            top: 0;
            width: 65vmin;
            height: 100vh;
            background: rgba(0, 0, 0, 0.9);
            padding: 4rem 5rem;
            text-align: left;
            overflow: auto;
            transform: translateX(100%);
            opacity: 0;
            pointer-events: none;
            transition: transform 0.5s ease-in-out,
                        opacity 0.5s ease-in-out;

            [data-navigation-open] & {

                transform: translateX(0);
                opacity: 1;
                pointer-events: all;

            }
        }

        @media (width <= 480px) {

            min-width: 100%;
            max-width: 100%;
            width: 100%;

        }

        a {

            display: block;
            color: rgb(58, 58, 255);
            text-decoration: none;
            padding-bottom: 0.25rem;
            margin-top: 0.25rem;
            margin-bottom: -0.25rem;
            transition: all 0.15s ease-out;
            transition-property: background-size, color;
            background-image: linear-gradient(transparent, transparent),
                              linear-gradient(rgb(58, 58, 255), rgb(58, 58, 255));
            background-size: 100% 0.05em, 0 0.05em;
            background-repeat: no-repeat;
            background-position: bottom,right 100%;

            @media (width <= 768px) {

                text-align: center;
                width: auto;
                align-self: center;
                color: white;
                margin: 0.5em;
                font-size: 4vmax;

            }

            @media (width <= 500px) {

                margin: 1em;

            }

            &[data-current="1"] {

                color: rgb(5, 241, 159);
                background-image: linear-gradient(transparent, transparent),
                                  linear-gradient(rgb(5, 241, 159), rgb(5, 241, 159));

                &[data-hover="0"] {

                        background-position: bottom, right 100%;
                        background-size: 100% 0.05em, 0 0.05em;

                }
            }

            &:hover,
            &[data-current="1"] {

                background-size: 100% 0.05em;
                background-position: bottom, 0 100%;

            }
        }
    }
}
#home-clients {
    margin-top: 3.25rem;
}
@keyframes blip {

    0% {
        opacity: 0;
        transform: scale(1);
    }
    25% {
        opacity: 1;
        transform: scale(1);
    }
    75% {
        opacity: 1;
        transform: scale(1.2);
    }
    100% {
        opacity: 0;
        transform: scale(1.3);
    }
}

@keyframes float {

    0% { transform: translateY(0) rotate(10deg); }
    25% { transform: translateY(0.5rem) rotate(0deg); }
    50% { transform: translateY(0rem) rotate(-10deg); }
    75% { transform: translateY(0.5rem) rotate(0deg); }
    100% { transform: translateY(0) rotate(10deg); }
    
}

@keyframes star {

    0% { transform: rotate(0deg) scale(0.7); }
    50% { transform: rotate(180deg) scale(1.3); }
    100% { transform: rotate(360deg) scale(0.7); }
    
}

@keyframes rotate {

    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
    
}
#marquees {
    margin-top: 1.35rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    @media (min-width: 1280px) {
        gap: 2.5rem;
    }
}

.marquee-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    a {
        text-decoration: none;
    }

    @media (min-width: 1280px) {
        height: 65vh;
    }
}

.marquee {
    font-size: 4.75rem;
    font-weight: 900;
    line-height: 1;
    letter-spacing: 0.075em;
    text-transform: uppercase;

    -webkit-text-stroke-color: white;
    -webkit-text-stroke-width: 0.01em;
    -webkit-text-fill-color: transparent;

    @media (width <= 768px) {
        font-size: 5.5rem;
    }

    @media (width <= 576px) {
        font-size: 6.25rem;
    }

    @media (width <= 384px) {
        font-size: 7rem;
    }

    &:hover {
        .marquee-content:before {
            opacity: 1;
        }
    }

    &__home {
        .marquee-wrapper {
            position: absolute;
            bottom: -.9rem;
        }
    }
}

.marquee-wrapper {
    display: flex;
    overflow: visible;
    animation: marquee 15s linear infinite;
}

.marquee-content {
    display: block;
    padding-right: 2rem;
    position: relative;
    white-space: nowrap;

    &:before {
        content: attr(data-text);
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        transition: opacity 0.5s ease-out;
        -webkit-text-fill-color: white;
    }
}

@keyframes marquee {
    0% { transform: translateX(0%) }
    100% { transform: translateX(-100%) }
}

@-webkit-keyframes marquee {
    0% { transform: translateX(0%) }
    100% { transform: translateX(-100%) }
}

@-moz-keyframes marquee {
    0% { transform: translateX(0%) }
    100% { transform: translateX(-100%) }
}

@-o-keyframes marquee {
    0% { transform: translateX(0%) }
    100% { transform: translateX(-100%) }
}
.masthead {

    #error-404 {

        [id^="star"] {
            
            @supports (transform-box: fill-box) {

                transform-box: fill-box;
                transform-origin: center center;
                animation-name: star;
                animation-direction: normal;
                animation-iteration-count: infinite;
                animation-timing-function: linear;

            }
        }

        #star-1 {
            animation-duration: 6s;
        }

        #star-2 {
            animation-duration: 7s;
        }

        #star-3 {
            animation-duration: 8s;
        }

        #star-4 {
            animation-duration: 9s;
        }

        [id^="number"],
        [id^="astronaut"] {

            @supports (transform-box: fill-box) {

                transform-box: fill-box;
                transform-origin: center center;
                animation: float infinite cubic-bezier(0.455, 0.03, 0.515, 0.955);
            
            }
        }

        #number-4 {
            animation-duration: 14s;
        }

        #astronaut {
            animation-duration: 25s;
        }

        #man-arm-left {

            @supports (transform-box: fill-box) {

                transform-origin: center;
                animation: error-man-arm-left 5s infinite cubic-bezier(0.455, 0.03, 0.515, 0.955);

            }
        }

    }
}

@keyframes error-man-arm-left {

    0% { transform: translateY(0); }
    75% { transform: translateY(-9px) rotate(0.5deg); }
    100% { transform: translateY(0); }

}
.masthead {

    #clients {

        [id^="blip"] {

            @supports (transform-box: fill-box) {

                transform-box: fill-box;
                transform-origin: center center;
                animation: blip normal infinite cubic-bezier(0.455, 0.03, 0.515, 0.955);
            
            }
        }

        #blip-1 {
            animation-duration: 5s;
        }

        [id^="float"] {

            @supports (transform-box: fill-box) {

                transform-box: fill-box;
                transform-origin: center center;
                animation: float infinite cubic-bezier(0.455, 0.03, 0.515, 0.955);
            
            }
        }

        #float-1 {
            animation-duration: 14s;
        }

        #float-2 {
            animation-duration: 15s;
        }

        [id^="star"] {
            
            @supports (transform-box: fill-box) {

                transform-box: fill-box;
                transform-origin: center center;
                animation: star infinite linear;

            }
        }

        #star-1 {
            animation-duration: 6s;
        }

        #star-2 {
            animation-duration: 7s;
        }

        #star-3 {
            animation-duration: 8s;
        }

        #star-4 {
            animation-duration: 9s;
        }

        [id^="rotate"] {
            
            @supports (transform-box: fill-box) {

                transform-box: fill-box;
                transform-origin: center center;
                animation: rotate infinite linear;

            }
        }

        #rotate-1 {
            animation-duration: 12s;
        }

        #man-sweater {

            @supports (transform-box: fill-box) {

                transform-origin: center;
                animation: clients-man-sweater 5s infinite cubic-bezier(0.455, 0.03, 0.515, 0.955);

            }
        }

        #woman-flag {
            
            @supports (transform-box: fill-box) {

                transform-box: fill-box;
                transform-origin: center left;
                animation: clients-woman-flag 8s alternate infinite cubic-bezier(0.455, 0.03, 0.515, 0.955);

            }
        }

        #woman-apple {
            
            @supports (transform-box: fill-box) {

                transform-box: fill-box;
                transform-origin: center right;
                animation: clients-woman-apple 4s alternate infinite cubic-bezier(0.455, 0.03, 0.515, 0.955);

            }
        }
    }
}

@keyframes clients-man-sweater {

    0% { transform: translateY(0); }
    75% { transform: translateY(0); }
    85% { transform: translateY(-5px) rotate(0.5deg); }
    100% { transform: translateY(0); }

}

@keyframes clients-woman-flag {

    0% { transform: rotate(-5deg) translate3d(-5px, 0, 0); }
    100% { transform: rotate(0deg) translate3d(0, 0, 0);  }

}

@keyframes clients-woman-apple {

    0% { transform: rotate(10deg); }
    100% { transform: translateY(0deg); }

}
.masthead {

    #contact {

        [id^="blip"] {

            @supports (transform-box: fill-box) {

                transform-box: fill-box;
                transform-origin: center center;
                animation-name: blip;
                animation-direction: normal;
                animation-iteration-count: infinite;
                animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
            
            }
        }

        #blip-1 {
            animation-duration: 5s;
        }

        #blip-2 {
            animation-duration: 3s;
        }

        #blip-3 {
            animation-duration: 7s;
        }

        [id^="social"] {

            @supports (transform-box: fill-box) {

                transform-box: fill-box;
                transform-origin: center center;
                animation-name: float;
                animation-direction: normal;
                animation-iteration-count: infinite;
                animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
            
            }
        }

        #social-1 {
            animation-duration: 10s;
        }

        #social-2 {
            animation-duration: 8s;
        }

        #social-3 {
            animation-duration: 12s;
        }

        #social-4 {
            animation-duration: 14s;
        }

        #social-5 {
            animation-duration: 9s;
        }

        #social-6 {
            animation-duration: 15s;
        }

        [id^="star"] {
            
            @supports (transform-box: fill-box) {

                transform-box: fill-box;
                transform-origin: center center;
                animation-name: star;
                animation-direction: normal;
                animation-iteration-count: infinite;
                animation-timing-function: linear;

            }
        }

        #star-1 {
            animation-duration: 6s;
        }

        #star-2 {
            animation-duration: 7s;
        }

        #star-3 {
            animation-duration: 8s;
        }

        #star-4 {
            animation-duration: 9s;
        }

        #star-5 {
            animation-duration: 10s;
        }

        #star-6 {
            animation-duration: 11s;
        }

        #cloud-1 {

            animation: contact-cloud 12s alternate infinite cubic-bezier(0.455, 0.03, 0.515, 0.955);

        }
    }
}

@keyframes contact-cloud {

    0% { transform: translateX(-20px); }
    100% { transform: translateX(20px);  }
    
}
.masthead {

    position: relative;
    z-index: 2;

    &.projects {

        h1 {

            transform: translate3d(-39%,-57%,0);

        }
    }

    &.team {

        h1 {

            transform: translate3d(-48%, -63%, 0);

        }
    }

    &.error-404 {

        h1 {

            font-size: 4.5rem;
            line-height: 4rem;

            text-shadow: 0.045em 0.045em 0 #231f20,
                     -0.01em -0.01em 0 #231f20,  
                     0.01em -0.01em 0 #231f20,
                     -0.01em  0.01em 0 transparent,
                     0.01em  0.01em 0 transparent;

            transform: translate3d(-94%,-113%,0);

            @supports(-webkit-text-stroke-color: white) {

                text-shadow: none;

                -webkit-text-stroke-color: rgb(35, 31, 32);
                -webkit-text-stroke-width: 0.01em;
                -webkit-text-fill-color:   transparent;

            }

            span {

                font-size: 4.5rem;
                line-height: 4rem;

                &:last-child {

                    line-height: 5rem;

                }

            }

        }

    }

    h1 {

        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
        font-size: 4.75rem;
        font-weight: 900;
        margin: 0;

        color: rgb(3, 234, 152);
            
        text-shadow: 0.045em 0.045em 0 white,
                     -0.01em -0.01em 0 white,  
                     0.01em -0.01em 0 white,
                     -0.01em  0.01em 0 transparent,
                     0.01em  0.01em 0 transparent;

        @supports(-webkit-text-stroke-color: white) {

            text-shadow: none;

            -webkit-text-stroke-color: white;
            -webkit-text-stroke-width: 0.01em;
            -webkit-text-fill-color: rgb(3, 234, 152);

        }

    }

    p {

        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate3d(-118%,-19%,0);
        font-size: 1.5rem;
        width: 15rem;
        line-height: 1.85rem;
        font-weight: 300;
        margin: 0;
        font-family: "Overpass", sans-serif;

        color: rgb(35, 31, 32);

        span {

            font-style:  italic; 
            font-weight: 400;

        }

    }

    svg {

        width: 100%;
        display: block;

    }
}
.masthead.policy {

        height: 55vh;

        h1 {
                text-align: center;
                line-height: 90%; 
        }

}
.masthead {

    #projects {

        [id^="blip"] {

            @supports (transform-box: fill-box) {

                transform-box: fill-box;
                transform-origin: center center;
                animation-name: blip;
                animation-direction: normal;
                animation-iteration-count: infinite;
                animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
            
            }
        }

        #blip-1 {
            animation-duration: 5s;
        }

        #blip-2 {
            animation-duration: 3s;
        }

        [id^="float"] {

            @supports (transform-box: fill-box) {

                transform-box: fill-box;
                transform-origin: center center;
                animation-name: float;
                animation-direction: normal;
                animation-iteration-count: infinite;
                animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
            
            }
        }

        #float-1 {
            animation-duration: 10s;
        }

        #float-2 {
            animation-duration: 8s;
        }

        #float-3 {
            animation-duration: 12s;
        }

        #float-4 {
            animation-duration: 14s;
        }

        [id^="star"] {
            
            @supports (transform-box: fill-box) {

                transform-box: fill-box;
                transform-origin: center center;
                animation-name: star;
                animation-direction: normal;
                animation-iteration-count: infinite;
                animation-timing-function: linear;

            }
        }

        #star-1 {
            animation-duration: 6s;
        }

        #star-2 {
            animation-duration: 7s;
        }

        #star-3 {
            animation-duration: 8s;
        }

        #star-4 {
            animation-duration: 9s;
        }

        #star-5 {
            animation-duration: 10s;
        }

        #star-6 {
            animation-duration: 11s;
        }

        [id^="rotate"] {
            
            @supports (transform-box: fill-box) {

                transform-box: fill-box;
                transform-origin: center center;
                animation-name: rotate;
                animation-direction: normal;
                animation-iteration-count: infinite;
                animation-timing-function: linear;

            }
        }

        #rotate-1 {
            animation-duration: 8s;
        }

        #rotate-2 {
            animation-duration: 9s;
        }
    }
}
.masthead {

    #team {

        [id^="blip"] {

            @supports (transform-box: fill-box) {

                transform-box: fill-box;
                transform-origin: center;
                animation: blip normal infinite cubic-bezier(0.455, 0.03, 0.515, 0.955);
            
            }
        }

        #blip-1 {
            animation-duration: 5s;
        }

        [id^="float"] {

            @supports (transform-box: fill-box) {

                transform-box: fill-box;
                transform-origin: center center;
                animation: float infinite cubic-bezier(0.455, 0.03, 0.515, 0.955);
            
            }
        }

        #float-1 {
            animation-duration: 13s;
        }

        #float-2 {
            animation-duration: 15s;
        }

        #float-3 {
            animation-duration: 12s;
        }

        #float-4 {
            animation-duration: 10s;
        }

        #float-5 {
            animation-duration: 16s;
        }

        #float-6 {
            animation-duration: 11s;
        }

        #float-7 {
            animation-duration: 17s;
        }

        [id^="star"] {
            
            @supports (transform-box: fill-box) {

                transform-box: fill-box;
                transform-origin: center center;
                animation: star infinite linear;

            }
        }

        #star-1 {
            animation-duration: 6s;
        }

        #star-2 {
            animation-duration: 7s;
        }

        #star-3 {
            animation-duration: 8s;
        }

        #star-4 {
            animation-duration: 9s;
        }

        #man-head {

            @supports (transform-box: fill-box) {

                transform-box: fill-box;
                transform-origin: bottom center;
                animation: work-session-man-head 2s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite alternate;

            }
        }

        #man-moustache {

            animation: work-session-man-moustache 1.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite alternate;

        }

        #woman-right-arm {

            @supports (transform-box: fill-box) {

                transform-box: fill-box;
                transform-origin: bottom left;
                animation: team-woman-right-arm 4s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite alternate;

            }
        }

        #woman-left-arm {

            @supports (transform-box: fill-box) {

                transform-box: fill-box;
                transform-origin: bottom right;
                animation: team-woman-left-arm 5s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite alternate;

            }
        }

        #woman-foot {

            @supports (transform-box: fill-box) {

                transform-box: fill-box;
                transform-origin: top right;
                animation: team-woman-foot 3s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite alternate;

            }
        }
    }
}

@keyframes team-woman-right-arm {

    0% { transform: rotate(-3deg); }
    100% { transform: rotate(6deg); }

}

@keyframes team-woman-left-arm {

    0% { transform: rotate(0deg); }
    100% { transform: rotate(-9deg); }

}

@keyframes team-woman-foot {

    0% { transform: rotate(0deg); }
    100% { transform: translate3d(-3px, -5px, 0) rotate(-15deg); }

}
[data-particles] {

    position: relative;

    canvas {

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;

    }
}
.policy-text {

    @media (width <= 576px) {

            padding: 0 2rem;

        }

    p   {

        font-size:  1em;

        @media (width <= 576px) {

            font-size:  2rem;

        }
        
    }

    a   {

        display: inline-block;
        color: #000;
        padding-bottom: 0.1em;
        text-decoration: none;
        transition: all 0.15s ease-out;

        background-image: linear-gradient(transparent, transparent),
                                linear-gradient(white, white);
        background-size: 100% 0.05em, 0 0.05em;
        background-repeat: no-repeat;
        background-position: bottom, 0 100%;

        &:hover {

            background-size: 100% 0.05em;

        }
    }

    h3  {

        margin-top: 3rem;
        max-width: 40rem;
        padding-right: 3rem;

        @media (width <= 576px) {

            font-size:  2rem;

        }

    }

    ul  {

        margin: 1rem 0;

        @media (width <= 576px) {

            font-size:  2rem;
            margin: 0.5rem;
            padding: 1rem;

        }

        li {

            margin: 1rem 0;
            list-style: none;

            ul  {

                li  {
                    list-style: circle;
                }

            }

        }

    }
}
.projects[data-grid] {

    @media (width < 720px) {

        grid-template-columns: repeat(2, 1fr);
        grid-template-rows:    auto;

        @for $nth from 1 to 2 {

            > *:nth-child(2n + $nth) {

                transition-delay: calc(($nth - 1) * (0.5s / 2));

            }
        }

    }

    @media (width >= 720px) and (width <= 1600px) {

        grid-template-columns: repeat(3, 1fr);
        grid-template-rows:    auto;

        @for $nth from 1 to 3 {

            > *:nth-child(3n + $nth) {

                transition-delay: calc(($nth - 1) * (0.5s / 3));

            }
        }
    }

    @media (width > 1600px) {

        grid-template-columns: repeat(4, 1fr);
        grid-template-rows:    auto;

        @for $nth from 1 to 4 {

            > *:nth-child(4n + $nth) {

                transition-delay: calc(($nth - 1) * (0.5s / 4));

            }
        }
    }
}

.projects {

    li {

        > a {

            position: relative;
            overflow: hidden;

            @media (hover: none) {

                &:hover {

                    img {

                        transform:        translateY(0%);

                    }

                }

                > div {

                    transform:  translateY(0);

                }

            }

            &:hover {

                img {

                    transform:        translateY(-10%);
                    transition:       transform 0.3s ease-out;

                }

                > div {

                    transform:  translateY(0);
                    transition: transform 0.3s ease-out;

                }

            }

            img {

                transform:        translateY(0);
                transition:       transform 0.3s ease-in;

            }

            > div {

                position:   absolute;
                background: black;
                bottom:     0;
                width:      100%;
                color:      white;
                padding:    0.6rem 1rem;
                transform:  translateY(100%);
                transition: transform 0.3s ease-in;

                h2 {

                    text-transform: uppercase;
                    font-family:    Overpass,sans-serif;
                    font-size:      0.7rem;
                    margin-bottom:  0;

                    @media (width <= 576px) {

                        font-size:      1.2rem;

                    }

                }

                p {

                    font-family:         Playfair Display,serif;
                    font-style:          italic;
                    font-size:           0.55rem;
                    margin-top:          0.1rem;
                    letter-spacing:      0.04rem;
                    display:             flex;

                    @media (width <= 576px) {

                        font-size:      1rem;

                    }

                    &:before {

                        content:          "";
                        width:            1rem;
                        background-color: white;
                        height:           1px;
                        display:          flex;
                        align-self:       center;
                        margin-right:     0.5rem;

                    }

                }

            }

        }

    }

}

.project {

    margin-bottom: 3rem;

    .title{

        display:             grid;
        grid-auto-columns:   31% 69%;
        grid-template-areas: "empty h1";
        margin:              4rem 0 2rem;

        div {

            margin-bottom: 0;

        }

        h1 {

            color: #231f20;
            font-size: 3.5rem;
            font-weight: 900;
            font-family: Overpass, sans-serif;
            margin: 0;

            &.thin {

                font-weight: 300;

            }

        }

    }

    h2 {

        font-family: Playfair Display,serif;
        color: #1e36ff;
        font-style: italic;
        font-size: 1rem;
        display: flex;
        align-items: center;
        margin-bottom: 1.1rem;
        font-weight: 100;
        letter-spacing: 0.05rem;
        text-transform: lowercase;

        &#project-type {
            text-transform: none;
        }

        &:before {

            content:           "";
            width:             2rem;
            height:            .05rem;
            background-color:  #1e36ff;
            margin-right:      0.75rem;

        }

    }

    p + .client-project {

        margin: 1.2rem 0 0;
    }

    img + .client-project{

        margin: 1.2rem 0;

        &.margin-bottom {

            margin-bottom: 0;

        }

    }

    .client-project img {
        transform: none;
        opacity: 1;
    }

    img + .inverted {

        margin-top: 2.3rem;
        margin-bottom: 2.5rem;

    }

    .inverted {

        grid-template-columns: 31% 69%;
        grid-template-rows: auto;

        div {

            display: flex;
            flex-direction: column;
            margin-bottom: 0;

            small.colophon {
                font-size: .8rem;

                > *,
                a {
                    color: var(--black);
                    font-size: .8rem;
                    text-decoration: none;
                }

                :not(:first-child) {
                    margin-top: -.4rem;
                }

            }

        }

        p {

            padding-right: 0;
            font-size:     1.4rem;
            line-height:   1.7rem;

            @media (width <= 480px) {

                padding-right: 0;

            }

        }

    }

    div {

        display: flex;
        /* margin-bottom: 5.5rem; */

        &.inverted {

            flex-flow: row-reverse;

            @media (width <= 800px) {

                flex-flow: column-reverse;

            }

            > div:first-of-type {

                padding-left: 7rem;

                @media (width <= 1200px) and (width >= 800px) {

                    padding-left: 3rem;

                }

                @media (width <= 800px) {

                    padding-left: 0;

                }
            }
        }

        @media (width <= 800px) {

            flex-flow: column-reverse;
            margin-bottom: 0.5rem;

        }

        > div {

            display: flex;
            flex-flow: row;
            flex-flow: column;
            justify-content: flex-start;
            align-items: stretch;

            &.overlay,
            &.overlay:first-of-type {

                margin-bottom: 0;
                flex-basis: 100%;
                width: 100%;

            }

            &:first-of-type {

                width: 30%;
                flex-basis: 30%;

                @media (width <= 800px) {

                    width: 100%;
                    flex-basis: 100%;
                    margin-bottom: 2rem;

                }

            }

            &:last-of-type {

                width: 70%;
                flex-basis: 70%;

                @media (width <= 800px) {

                    width: 100%;
                    flex-basis: 100%;

                }
            }
        }

    }

    p {

        font-family: Overpass, sans-serif;
        font-size: 1.4rem;
        font-weight: 100;
        line-height: 1.3;
        margin: 0;

    }

    img + p,
    .client-project + p {

        margin: 4rem 8rem;

        @media (width <= 800px) {

            margin: 4rem;

        }

    }

    img + .videoWrapper {

        margin-top: 1.2rem;

    }

    .videoWrapper + .client-project {

        padding-top: 1.2rem;

    }

    ul:not(.view-project) {

        list-style-type: none;
        padding: 0;
        margin: 0;

        li {

            font-size: 1rem;
            line-height: 1.7rem;

            &:last-child {

                margin-bottom: 0;

            }

        }
    }

    .margin {

        margin-top: 2rem;

    }

    img,
    div[data-viewport] {

        transition: all 0.3s ease-out;
        transition-property: transform, opacity;
        transform: translateY(1rem);
        opacity: 0;

        &[data-viewport="1"] {

            transform: none;
            opacity: 1;

        }

    }

}
.splash {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: #3a3aff;
    height: 80vh;
    width: 100%;

    @media (min-width: 768px) {
        height: 70vh;
    }

    @media (min-width: 1280px) {
        height: 65vh;
        align-items: flex-start;
    }
}

.splash-particles {
    z-index: 2;
    width: 100% !important;
    height: 100% !important;
}

.splash-content {
    position: absolute;
    z-index: 2;

    @media (width > 576px) and (max-width: 768px) {
        font-size: 1.25rem;
    }

    @media (max-width: 576px) {
        font-size: 1.75rem;
    }

    @media (min-width: 725px) and (max-width: 1024px) {
        padding: 0 1.2rem;
        text-align: center;
    }

    @media (min-width: 1280px) {
        margin-top: 3rem;
    }

    @media (min-width: 1366px) {
        margin-top: 5rem;
    }

    h1 {
        font-family: Overpass;
        line-height: 1.05;
        font-weight: bold;
        color: white;
        font-size: 6vh;

        @media (width <= 725px) {
            font-size: 2.8em;
            margin: 0 2rem;
            text-align: center;
        }

        @media (min-width: 1366px) {
            margin: 0;
        }
    }

    p {
        font-family: "Playfair Display";
        font-style: italic;
        font-size: 3vh;
        line-height: 1.15;
        letter-spacing: 0.025em;
        color: rgb(5, 241, 159);

        @media (width <= 725px) {
            font-size: 1.25em;
            text-align: center;
        }
    }
}

.splash-more {
    position: absolute;
    display: flex;
    font-size: 1em;
    width: 8.9vh;
    width: 1.5em;
    height: 1.5em;
    overflow: hidden;
    text-indent: -999em;
    bottom: 2rem;
    justify-content: center;
    align-self: center;
    align-content: center;
    align-items: center;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    border: 0;
    background: url("/assets/icons/splash-arrow.svg") center / contain no-repeat;
    animation: splash-arrow 0.5s infinite alternate cubic-bezier(0.86, 0, 0.07, 1);
    cursor: pointer;
    outline: none;
    z-index: 2;

    @media (width < 960px) {
        width: calc(1.5em + (960px - 100vw) * 0.025);
        height: calc(1.5em + (960px - 100vw) * 0.025);
        bottom: calc(3rem + (960px - 100vw) * 0.1);
    }

    @media (min-width: 1024px) {
        bottom: 3rem;
    }

    @media (min-width: 1280px) {
        bottom: 4rem;
    }

    @media (min-width: 1366px) {
        width: 1em;
        height: 1.2em;
        bottom: 2.5rem;
    }
}

@keyframes splash-arrow {
    0% { transform: translate3d(-50%, 0, 0) }
    100% { transform: translate3d(-50%, 1rem, 0) }
}
.staircase {

    font-family: Overpass, sans-serif;
    display: flex;
    position: relative;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    line-height: 1.2;
    color: rgb(5, 243, 152);
    background: rgb(29, 54, 255);

    @media (width <= 576px) {

        font-size: 1.3rem;
        line-height: 1.1;

    }

    @media (width <= 384px) {

        font-size: 1.5rem;
        line-height: 1;

    }

    &:hover {

        svg {

            rect:nth-of-type(2) {

                transform: scale(1);

            }
        }

        small {

            background-size: 100% 1px;

        }
    }

    &[data-variant="projects"] {

        background: rgb(11, 27, 120);

        svg {

            rect {

                &:nth-of-type(1) {

                    fill: rgb(29, 54, 255);

                }

                &:nth-of-type(2) {

                    fill: rgb(5, 234, 152);

                }
            }
        }
    }

    &[data-variant="studio"] {

        background: rgb(5, 234, 152);

        &:hover {

            small {

                color: rgb(11, 27, 120);

            }
        }

        svg {

            rect {

                &:nth-of-type(1) {

                    fill: white;

                }

                &:nth-of-type(2) {

                    fill: rgb(11, 27, 120);

                }
            }
        }

        [data-step] {

            &:before {

                color: rgb(11, 27, 120);

            }

            &:after {

                color: rgb(29, 54, 255);

            }
        }

        small {

            color: rgb(29, 54, 255);
            background-image: linear-gradient(transparent, transparent),
                                    linear-gradient(rgb(11, 27, 120), rgb(11, 27, 120));

        }
    }

    &[data-variant="project-single"] {

        background: rgb(11, 27, 120);

        &:hover {

            [data-step] {

                background: rgb(11, 27, 120);

            }
        }

        svg {

            rect {

                &:nth-of-type(1) {

                    fill: rgb(29, 54, 255);

                }

                &:nth-of-type(2) {

                    fill: rgb(5, 234, 152);

                }
            }
        }
    }

    > div {

        position: relative;
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        justify-content: center;

        @media (width <= 800px) {

            flex-flow: column;
            margin-bottom: 0;

        }

    }

    svg {

        width: 4em;
        margin-bottom: 1em;
        display: block;

        rect {

            &:nth-of-type(1) {

                fill: rgb(11, 27, 120);

            }

            &:nth-of-type(2) {

                fill: rgb(5, 234, 152);
                transform: scale(1, 0);
                transform-origin: center bottom;
                transition: transform 0.5s ease-out;
                will-change: auto;

            }
        }
    }

    small {

        font-family: Playfair Display, serif;
        color: white;
        font-style: italic;
        font-size: 0.7em;
        padding-bottom: 0.3em;
        margin-top: 1em;
        margin-bottom: 0.3em;
        transition: all 0.5s ease-out;
        transition-property: color, background-size;

        background-image: linear-gradient(transparent, transparent),
                                linear-gradient(white, white);
        background-position: bottom, 0 calc(100% - 1px);
        background-repeat: no-repeat;
        background-size: 100% 1px, 0 1px;

        @media (width <= 576px) {

            font-size: 1em;

        }
    }
}

[data-step] {

    display: block;
    font-size: 1.5em;
    font-weight: bold;
    position: relative;
    overflow: hidden;
    color: transparent;

    @media (width <= 480px) {

        font-size: 1.7em;

    }

    @for $i from 1 to 4 {

        &:nth-of-type($i):before,
        &:nth-of-type($i):after {

            transition-delay: calc(($i - 1) * 0.1s);

        }
    }

    a:hover & {

        &:before {

            transform: translateY(-100%);

        }

        &:after {

            transform: translateY(0%);

        }
    }

    &:before,
    &:after {

        content: attr(data-step);
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transition: transform 0.3s ease-out;
        transform: translateY(0);
        will-change: auto;

    }

    &:before {

        color: rgb(5, 243, 152);

    }

    &:after {

        color: white;
        transform: translateY(100%);

    }
}
.stargaze {

    font-family: Overpass, sans-serif;
    display: flex;
    position: relative;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    line-height: 1.2;
    background: rgb(11, 27, 120);
    color: rgb(255, 255, 255);

    @media (width <= 576px) {

        font-size: 1.3rem;
        line-height: 1.1;

    }

    @media (width <= 384px) {

        font-size: 1.5rem;
        line-height: 1;

    }

    &:hover {

        svg {

            .front {

                transform: rotate(5deg);

            }

            .middle {

                transform: rotate(10deg);

            }

            .back {

                transform: rotate(15deg);

            }
        }

        strong {

            color: rgb(5, 243, 152);

        }

        small {

            color: white;
            background-size: 100% 1px;

        }
    }

    &[data-variant="projects"] {

        background: rgb(5, 234, 152);

        &:hover {

            strong {

                color: rgb(29, 54, 255);

            }

            small {

                color: rgb(11, 27, 120);

            }
        }

        svg {

            .front {

                fill: rgb(29, 54, 255);
                stroke: rgb(29, 54, 255);
                
            }

            .middle {

                fill: white;
                stroke: white;
                
            }

            .back {

                fill: rgb(29, 54, 255);
                stroke: rgb(29, 54, 255);
            }
        }

        strong {

            color: rgb(11, 27, 120);

        }

        small {

            color: rgb(29, 54, 255);
            background-image: linear-gradient(transparent, transparent),
                              linear-gradient(rgb(11, 27, 120), rgb(11, 27, 120));

        }
    }

    &[data-variant="studio"] {

        background: rgb(35, 31, 32);

        &:hover {

            strong {

                color: rgb(5, 234, 152);

            }

            small {

                color: white;

            }
        }

        svg {

            .front {

                fill: rgb(29, 54, 255);
                stroke: rgb(29, 54, 255);
                
            }

            .middle {

                fill: rgb(29, 54, 255);
                stroke: rgb(29, 54, 255);
                
            }

            .back {

                fill: rgb(29, 54, 255);
                stroke: rgb(29, 54, 255);
            }
        }

        strong {

            color: white;

        }

        small {

            color: rgb(5, 234, 152);
            background-image: linear-gradient(transparent, transparent),
                              linear-gradient(white, white);

        }
    }

    &[data-variant="project-single"] {

        background: rgb(29, 54, 255);

        svg {

            .front {

                fill: rgb(5, 234, 152);
                stroke: rgb(5, 234, 152);
                
            }

            .middle {

                fill: rgb(35, 31, 32);
                stroke: rgb(35, 31, 32);
                
            }

            .back {

                fill: rgb(5, 234, 152);
                stroke: rgb(5, 234, 152);
            }
        }

        [data-step] {

            border: 0.25em solid;
            border-right-width: 0.5em;
            border-left-width: 0.5em;
            margin: -0.13em 0;
            background: rgb(5, 234, 152);

            @media (width <= 630px) {

                margin: 0;

            }

            &:before {

                color: rgb(29, 54, 255);

            }
        }
    }

    > div {

        position: relative;
        display: flex;
        flex-flow: column;
        align-items: flex-start;

        @media (width <= 800px) {

            flex-flow: column;
            margin-bottom: 0;

        }

    }

    svg {

        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        z-index: 0;
        transform: scale(1.45);

        .front,
        .middle,
        .back {

            stroke-width: 0.5;
            transition: transform 0.8s ease-out;
            transform-origin: center;
            will-change: auto;

            @media (resolution >= 192dpi) {

                stroke-width: 0;

            }
        }

        .front {

            fill: rgb(29, 54, 255);
            stroke: rgb(29, 54, 255);
            
        }

        .middle {

            fill: rgb(98, 192, 140);
            stroke: rgb(98, 192, 140);
            
        }

        .back {

            fill: rgb(29, 54, 255);
            stroke: rgb(29, 54, 255);
        }
    }

    strong {

        font-weight: bold;
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        font-size: 1.5em;
        max-width: 7.5rem;
        min-width: auto;
        width: auto;
        transition: color 0.5s ease-out;
        
    }

    small {

        font-family: Playfair Display, serif;
        color: rgb(5, 243, 152);
        font-style: italic;
        font-size: 0.7em;
        padding-bottom: 0.3em;
        margin-top: 1em;
        margin-bottom: 0.3em;
        transition: all 0.5s ease-out;
        transition-property: color, background-size;

        background-image: linear-gradient(transparent, transparent),
                          linear-gradient(white, white);
        background-position: bottom, 0 calc(100% - 1px);
        background-repeat: no-repeat;
        background-size: 100% 1px, 0 1px;

        @media (width <= 576px) {

            font-size: 1em;

        }
    }
}
.studio-approach {

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-gap: 2rem;
    margin: 4.5rem 0 11.5rem;

    @media (width <= 574px) {

        grid-template-columns: 25rem 1fr;

    }

    .grouped-text {

        grid-row: 1;
        grid-column: 1;

        :first-child {

            margin-top: 0;

        }
    }

    h2 {

        font-weight: 700;
        font-size: 3rem;
        line-height: 1.2;
        max-width: 6em;
        color: rgb(29, 54, 255);
        grid-column: 2;
        margin-top: 0;

    }

    p {

        margin-right: 1.5rem;

    }

    #horizon {

        position: absolute;
        top: 100%;
        left: 50%;
        width: 100%;
        max-width: 25.75rem;
        transform: translate3d(-15.5rem, -3.5rem, 0);

        [id^="blip"] {

            @supports (transform-box: fill-box) {

                transform-box: fill-box;
                transform-origin: center center;
                animation-name: studio-blip;
                animation-direction: normal;
                animation-iteration-count: infinite;
                animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);

            }
        }

        #blip-1 {

            animation-duration: 5s;

        }

        #blip-2 {

            animation-duration: 6s;

        }

        #blip-3 {

            animation-duration: 8s;

        }

        [id^="cloud"] {

            @supports (transform-box: fill-box) {

                transform-box: fill-box;
                transform-origin: center center;
                animation-direction: normal;
                animation-direction: alternate;
                animation-iteration-count: infinite;
                animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);

            }
        }

        #cloud-1 {

            animation-name: horizon-cloud-a;
            animation-duration: 3s;

        }

        #cloud-2 {

            animation-name: horizon-cloud-b;
            animation-duration: 8s;

        }

        [id^="star"] {

            @supports (transform-box: fill-box) {

                transform-box: fill-box;
                transform-origin: center center;
                animation: star 10s linear infinite;

            }
        }

        #trajectory {

            stroke-dashoffset: 0;
            animation: horizon-trajectory 2s linear infinite;

        }

        #plane {

            @supports (transform-box: fill-box) {

                transform-box: fill-box;
                transform-origin: center center;
                animation: horizon-plane 2s alternate infinite cubic-bezier(0.455, 0.03, 0.515, 0.955);

            }
        }

        [id^="sky-cloud"] {

            @supports (transform-box: fill-box) {

                transform-box: fill-box;
                transform-origin: center center;
                animation-direction: normal;
                animation-direction: alternate;
                animation-iteration-count: infinite;
                animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);

            }
        }

        #sky-cloud-1 {

            animation-name: horizon-sky-cloud-1;
            animation-duration: 8s;

        }

        #sky-cloud-2 {

            animation-name: horizon-sky-cloud-2;
            animation-duration: 5s;

        }

        #sky-cloud-3 {

            animation-name: horizon-sky-cloud-3;
            animation-duration: 9s;

        }

        #sky-cloud-4 {

            animation-name: horizon-sky-cloud-4;
            animation-duration: 6s;

        }

        #sky-cloud-5 {

            animation-name: horizon-sky-cloud-5;
            animation-duration: 7s;

        }

        #sky-cloud-6 {

            animation-name: horizon-sky-cloud-6;
            animation-duration: 6s;

        }
    }

    .plane-animation {

        position: absolute;
        top: 100%;
        left: 50%;
        width: 100%;
        max-width: 25.75rem;
        transform: translate3d(-15.5rem, -3.5rem, 0);

        svg {

            width: 100%;

        }

        #plane {

            position: absolute;
            top: -3rem;
            left: 0;
            max-width: 4.75rem;

        }
    }
}

@keyframes horizon-cloud-a {

    0% { transform: translateX(-8px); }
    100% { transform: translateX(8px);  }
    
}

@keyframes horizon-cloud-b {

    0% { transform: translateX(-12px); }
    100% { transform: translateX(12px);  }
    
}

@keyframes horizon-trajectory {

    0% { stroke-dashoffset: 0; }
    100% { stroke-dashoffset: 43;  }
    
}

@keyframes horizon-plane {

    0% { transform: rotate(2deg) translate3d(-5px, 0, 0); }
    100% { transform: rotate(-6deg) translate3d(0, -5px, 0);  }
    
}

@keyframes horizon-sky-cloud-1 {

    0% { transform: translate3d(0, -2px, 0); }
    100% { transform: translate3d(2px, 12px, 0); }
    
}

@keyframes horizon-sky-cloud-2 {

    0% { transform: translate3d(-4px, 0, 0); }
    100% { transform: translate3d(4px, 2px, 0); }
    
}

@keyframes horizon-sky-cloud-3 {

    0% { transform: scale(1.1) translate3d(3px, -5px, 0); }
    100% { transform: scale(1.1) translate3d(-3px, 3px, 0);  }
    
}

@keyframes horizon-sky-cloud-4 {

    0% { transform: translateX(8px); }
    100% { transform: translateX(-8px);  }
    
}

@keyframes horizon-sky-cloud-5 {

    0% { transform: translate3d(-5px, -10px, 0); }
    100% { transform: translate3d(5px, 5px, 0);  }
    
}

@keyframes horizon-sky-cloud-6 {

    0% { transform: scale(1.1) translate3d(-8px, -2px, 0); }
    100% { transform: scale(1.1) translate3d(10px, 2px, 0);  }
    
}
.studio-contact {

    min-height: 21rem;
    margin-top: 6rem;
    margin-bottom: 3rem;

    &[data-viewport="1"] {

        #sextans-trace {

            stroke-dashoffset: 0;
            transition-duration: 3.5s;

        }
    }

    h2 {

        font-family: Overpass;
        font-size: 1.6rem;
        line-height: 1.2;
        letter-spacing: 0.025em;
        font-weight: 700;
        color: rgb(30, 54, 255);
        margin: 0 auto;
        text-align: center;
        transition: transform 0.3s ease-out;

        @media (width <= 574px) {

            font-size: 2rem;

        }
    }

    a {

        text-decoration: none;
        color: inherit;
        display: block;
        max-width: 19rem;
        padding: 4rem 2rem;
        margin: 0 auto;

        @media (width <= 574px) {

            max-width: 24rem;

        }
    
        &:hover {

            h2 {

                transform: scale(1.1);

            }

            ~ #sextans-trace path {

                fill: #05eb99;
                transition-duration: 0.8s;

            }
        }
    }

    #sextans-trace {

        width: 13.5rem;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(calc(-50% + 1.5rem));
        z-index: -1;
        stroke-dasharray: 900;
        stroke-dashoffset: 900;
        transition: stroke-dashoffset 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);

        path {

            fill: transparent;
            transition: fill 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);

        }
    }

    #work-session {

        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;

        #woman-arm {

            @supports (transform-box: fill-box) {

                transform-box: fill-box;
                transform-origin: bottom left;
                animation: work-session-woman-arm 3s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite alternate;

            }
        }

        #woman-pen {

            @supports (transform-box: fill-box) {

                transform-box: fill-box;
                transform-origin: center center;
                animation: work-session-woman-pen 2s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite alternate;

            }
        }

        #man-head {

            @supports (transform-box: fill-box) {

                transform-box: fill-box;
                transform-origin: bottom center;
                animation: work-session-man-head 2s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite alternate;

            }
        }

        #man-moustache {

            animation: work-session-man-moustache 1.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite alternate;

        }

        #man-arm {

            animation: work-session-man-arm 3s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite alternate;

        }
    }
}

@keyframes work-session-woman-arm {

    0% { transform: rotate(-4deg); }
    100% { transform: rotate(2deg); }

}

@keyframes work-session-woman-pen {

    0% { transform: rotate(-5deg) translateX(0); }
    25% { transform: rotate(-2.5deg) translateX(1px); }
    50% { transform: rotate(0deg) translateX(0); }
    100% { transform: rotate(5deg) translateX(0); }

}

@keyframes work-session-man-head {

    0% { transform: rotate(-4deg); }
    100% { transform: rotate(12deg); }

}

@keyframes work-session-man-moustache {

    0% { transform: translateY(0); }
    75% { transform: translateY(0); }
    85% { transform: translateY(-1px); }
    95% { transform: translateY(1px); }
    100% { transform: translateY(0); }

}

@keyframes work-session-man-arm {

    0% { transform: translateX(0); }
    75% { transform: translateX(-1px); }
    100% { transform: translateX(2px); }

}
.studio-development {

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-gap: 2rem;

    @media (width <= 574px) {

        grid-template-columns: 1fr 25rem;

    }

    .grouped-text {

        :first-child {

            margin-top: 0;

        }
    }

    h2 {

        font-weight: 700;
        font-size: 3rem;
        line-height: 1.2;
        max-width: 6em;
        color: rgb(29, 54, 255);
        margin-top: 0;
        z-index: 2;
    }

    p {

        margin-right: 0.5rem;
        z-index: 2;

    }

    #planning {

        position: absolute;
        width: 16.5rem;
        z-index: 1;
        transform: translate3d(0.5rem, 9.5rem, 0);

        #woman-torso {

            @supports (transform-box: fill-box) {

                transform-box: fill-box;
                transform-origin: bottom left;
                animation: planning-woman-torso 6s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite alternate;

            }
        }

        [id^="blip"] {

            @supports (transform-box: fill-box) {

                transform-box: fill-box;
                transform-origin: center center;
                animation-name: studio-blip;
                animation-direction: normal;
                animation-iteration-count: infinite;
                animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
            
            }
        }

        #blip-1 {
            animation-duration: 5s;
        }

        #blip-2 {
            animation-duration: 3s;
        }

        #blip-3 {
            animation-duration: 7s;
        }

        #segment-1 {

            @supports (transform-box: fill-box) {

                transform-box: fill-box;
                transform-origin: bottom center;
                animation: planning-segment 10s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite alternate;

            }
        }

        #star-1 {

            @supports (transform-box: fill-box) {

                transform-box: fill-box;
                transform-origin: center center;
                animation: star 6s linear infinite;
                
            }
        }
    }
}

@keyframes planning-woman-torso {

    0% { transform: rotate(-4deg); }
    100% { transform: rotate(2deg); }

}

@keyframes planning-segment {

    0% { transform: rotate(12deg); }
    100% { transform: rotate(-12deg); }

}
.studio-experience {

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-gap: 2rem;

    @media (width <= 574px) {

        grid-template-columns: 1fr 25rem;

    }

    #thirty-years {

        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transform: translate3d(-0.25rem, -1.25rem, 0);

        #balloon-2,
        #balloon-2-shadow {

            animation: twenty-years-balloon-2 5s alternate infinite cubic-bezier(0.455, 0.03, 0.515, 0.955);
        
        }

        #balloon-0 {

            animation: twenty-years-balloon-0 7s alternate infinite cubic-bezier(0.455, 0.03, 0.515, 0.955);
        
        }

        [id^="blip"] {

            @supports (transform-box: fill-box) {

                transform-box: fill-box;
                transform-origin: center center;
                animation-name: studio-blip;
                animation-direction: normal;
                animation-iteration-count: infinite;
                animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);

            }
        }

        #blip-1 {

            animation-duration: 5s;

        }

        #blip-2 {

            animation-duration: 3s;

        }

        #blip-3 {

            animation-duration: 7s;

        }

        #blip-4 {

            animation-duration: 9s;

        }

        #blip-5 {

            animation-duration: 2s;

        }

        [id^="confetti"] {

            @supports (transform-box: fill-box) {

                transform-box: fill-box;
                transform-origin: center center;
                animation-name: twenty-years-confetti-a;
                animation-direction: alternate;
                animation-iteration-count: infinite;
                animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);

            }
        }

        #confetti-1 {

            animation-duration: 9s;

        }

        #confetti-2 {

            animation-duration: 12s;

        }

        #confetti-3 {

            animation-duration: 15s;
            animation-name: twenty-years-confetti-b;

        }

        #cloud-1 {

            animation: twenty-years-cloud 12s alternate infinite cubic-bezier(0.455, 0.03, 0.515, 0.955);
        
        }

        [id^="star"] {

            @supports (transform-box: fill-box) {

                transform-box: fill-box;
                transform-origin: center center;
                animation-name: star;
                animation-iteration-count: infinite;
                animation-timing-function: linear;

            }
        }

        #star-1 {

            animation-duration: 6s;

        }

        #star-2 {

            animation-duration: 9s;

        }
    }

    p {

        grid-column: 2;
        margin-right: 1.5rem;
        align-self: flex-end;
        
    }
}

@keyframes twenty-years-balloon-2 {

    0% { transform: translateY(0); }
    100% { transform: translateY(30px); }

}

@keyframes twenty-years-balloon-0 {

    0% { transform: translateY(0); }
    100% { transform: translateY(-20px); }

}

@keyframes twenty-years-confetti-a {

    0% { transform: rotate(0deg); }
    100% { transform: rotate(270deg); }

}

@keyframes twenty-years-confetti-b {

    0% { transform: rotate(0deg); }
    100% { transform: rotate(540deg); }
    
}

@keyframes twenty-years-cloud {

    0% { transform: translateX(-20px); }
    100% { transform: translateX(20px);  }
    
}
.studio-intro {

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: 2rem;

    h1 {

        font-weight: 700;
        z-index: 2;
        font-size: 1.6rem;
        margin: 0;
        color: rgb(30, 54, 255);
        position: absolute;
        top: 0;
        left: 0;
        transform: translate3d(2rem, 9.25rem, 0);

        strong {

            display: block;
            font-size: 4rem;
            font-weight: 700;
            text-transform: lowercase;
            color: white;
            position: absolute;
            left: 100%;
            top: 0;
            transform: translate3d(6.75rem, -2.5rem, 0);
            max-width: 5em;
            line-height: 1;
            text-indent: -1.1em;

        }
    }

    p {

        align-self: flex-end;
        margin-top: 17.5rem;
        max-width: 17.5rem;
        z-index: 2;

        @media (width <= 574px) {

            max-width: 25rem;

        }
    }

    #intro {

        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        width: 100%;

        [id^="blip"] {

            @supports (transform-box: fill-box) {

                transform-box: fill-box;
                transform-origin: center center;
                animation-name: studio-blip;
                animation-direction: normal;
                animation-iteration-count: infinite;
                animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);

            }
        }

        #blip-1 {

            animation-duration: 7s;

        }

        #blip-2 {

            animation-duration: 5s;

        }

        #blip-3 {

            animation-duration: 3s;

        }

        #blip-4 {

            animation-duration: 8s;

        }

        #blip-5 {

            animation-duration: 6s;

        }

        [id^="segment"] {
            
            @supports (transform-box: fill-box) {

                transform-box: fill-box;
                transform-origin: center center;
                animation-direction: alternate;
                animation-iteration-count: infinite;
                animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);

            }
        }

        #segment-1 {

            animation-name: intro-segment-a;
            animation-duration: 7s;

        }

        #segment-2 {

            animation-name: intro-segment-b;
            animation-duration: 5s;

        }

        [id^="star"] {
            
            @supports (transform-box: fill-box) {

                transform-box: fill-box;
                transform-origin: center center;
                animation-name: star;
                animation-direction: normal;
                animation-iteration-count: infinite;
                animation-timing-function: linear;

            }
        }

        #star-1 {

            animation-duration: 7s;

        }

        #star-2 {

            animation-duration: 6s;

        }

        #star-3 {

            animation-duration: 5s;

        }

        #segment-2 {

            animation-name: intro-segment-2;
            animation-duration: 5s;

        }
    }
}

@keyframes intro-segment-a {

    0% { transform: rotate(-5deg); }
    100% { transform: rotate(8deg); }

}

@keyframes intro-segment-b {

    0% { transform: rotate(6deg); }
    100% { transform: rotate(-4deg); }

}
.studio-name {

    margin-top: 6rem;

    h2 {

        font-weight: 700;
        font-size: 3rem;
        line-height: 1.2;
        max-width: 6em;
        color: rgb(29, 54, 255);

    }

    a {

        display: flex;
        position: absolute;
        top: 9.75rem;
        left: 25rem;
        transform: translate3d(-50%, -50%, 0);
        font-size: 0.9rem;
        line-height: 1.2;
        letter-spacing: 0.025em;
        color: rgb(29, 54, 255);
        font-family: "Playfair Display";
        font-style: italic;
        text-decoration: none;
        max-width: 9em;
        align-items: center;
        transition: color 0.15s ease-out;

        @media (max-width: 540px) {

            font-size: 1.2rem;

        }

        &:hover {

            color: black;

            &:before {

                background: black;

            }
        }

        &:before {

            content: "";
            width: 4em;
            height: 1px;
            display: block;
            margin-right: 0.5em;
            background: rgb(29, 54, 255);
            flex-shrink: 0;
            transition: background 0.15s ease-out;

        }
    }

    #sextant {

        position: absolute;
        left: 0;
        top: 0;
        transform: translate3d(6.25rem, -12.75rem, 0);
        width: 18rem;
        z-index: -1;

        #symbol {

            stroke-dasharray: 100;
            stroke-dashoffset: 100;

        }

        [id^="blip"] {

            @supports (transform-box: fill-box) {

                transform-box: fill-box;
                transform-origin: center center;
                animation-name: studio-blip;
                animation-direction: normal;
                animation-iteration-count: infinite;
                animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);

            }
        }

        #blip-1 {

            animation-duration: 5s;

        }

        #segment-1 {

            @supports (transform-box: fill-box) {

                transform-box: fill-box;
                transform-origin: top left;
                animation: sextant-segment-a 5s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite alternate;

            }
        }

        #segment-2 {
            
            @supports (transform-box: fill-box) {

                transform-box: fill-box;
                transform-origin: center center;
                animation: sextant-segment-b 25s linear infinite;

            }
        }
    }

    #man-planet {

        position: absolute;
        right: 0;
        top: 0;
        transform: translate3d(0rem, -5.5rem, 0);
        width: 11.25rem;
        z-index: -1;

        [id^="man-limbs"] {

            @supports (transform-box: fill-box) {

                transform-box: fill-box;
                animation-name: man-planet-man-limbs;
                animation-duration: 7s;
                animation-direction: alternate;
                animation-iteration-count: infinite;
                animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);

            }
        }

        #man-limbs-arm-left {

            transform-origin: right bottom;

        }

        #man-limbs-arm-right {

            transform-origin: left bottom;

        }

        [id^="man-limbs-foot"] {

            transform-origin: top right;

        }

        #planet-outer {

            @supports (transform-box: fill-box) {

                transform-box: fill-box;
                transform-origin: center center;
                animation: man-planet-planet-outer 3s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite alternate;

            }
        }

        #planet-inner {

            @supports (transform-box: fill-box) {

                transform-box: fill-box;
                transform-origin: center center;
                animation: man-planet-planet-inner 7s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite alternate;

            }
        }

        [id^="blip"] {

            @supports (transform-box: fill-box) {

                transform-box: fill-box;
                transform-origin: center center;
                animation-name: studio-blip;
                animation-direction: normal;
                animation-iteration-count: infinite;
                animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);

            }
        }

        #blip-1 {

            animation-duration: 7s;

        }

        #blip-2 {

            animation-duration: 5s;

        }

        #blip-3 {

            animation-duration: 3s;

        }

        #segment-1 {

            @supports (transform-box: fill-box) {

                transform-box: fill-box;
                transform-origin: right center;
                animation: man-planet-segment 15s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite alternate;

            }
        }
    }
}

@keyframes man-planet-planet-outer {

    0% { transform: rotate(-5deg); }
    100% { transform: rotate(25deg); }

}

@keyframes man-planet-planet-inner {

    0% { transform: translate3d(5px, 15px, 0); }
    100% { transform: translate3d(-5px, -15px, 0); }

}

@keyframes man-planet-segment {

    0% { transform: rotate(6deg); }
    100% { transform: rotate(-22deg); }

}

@keyframes man-planet-man-limbs {

    0% { transform: rotate(-5deg); }
    100% { transform: rotate(5deg); }

}

@keyframes sextant-segment-a {

    0% { transform: rotate(6deg); }
    100% { transform: rotate(-6deg); }

}

@keyframes sextant-segment-b {

    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }

}
.studio-container {

    margin-left: 2.5rem;
    margin-right: 2.5rem;
    overflow: visible;
    position: relative;
    font-family: Overpass;
    line-height: 1.3;
    font-size: 2.08333vw;
    z-index: 2;

    @media (width <= 384px) {

        margin-left: 1rem;
        margin-right: 1rem;

    }

    p {

        font-weight: 300;
        font-size: 1.5rem;
        color: rgb(35, 31, 32);
        z-index: 2;

        @media (width <= 574px) {

            font-size: 2rem;

        }
    }

    svg {

        overflow: visible;
        z-index: 1;

    }
}

@keyframes studio-blip {

    0% {
        opacity: 0;
        transform: scale(1);
    }
    25% {
        opacity: 1;
        transform: scale(1);
    }
    75% {
        opacity: 1;
        transform: scale(1.2);
    }
    100% {
        opacity: 0;
        transform: scale(1.3);
    }
}

@keyframes studio-float {

    0% { transform: translateY(0) rotate(10deg); }
    25% { transform: translateY(0.5rem) rotate(0deg); }
    50% { transform: translateY(0rem) rotate(-10deg); }
    75% { transform: translateY(0.5rem) rotate(0deg); }
    100% { transform: translateY(0) rotate(10deg); }
    
}
.swiper-container {

    width: calc(100% + 5rem);
    height: 100vh;
    margin: -7rem -2.5rem -2.5rem;
    padding: 7rem 2.5rem 2.5rem;
    position: relative;
    overflow: hidden;

    @media (width <= 576px) {

        width: calc(100% + 2rem);
        margin-left: -1rem;
        margin-right: -1rem;
        margin-bottom: -1rem;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 1rem;

    }
}

.swiper-wrapper {

    display: flex;
    flex-direction: column;
    
}

.swiper-slide {

    display: flex;
    overflow: hidden;

}

.swiper-scrollable {

    overflow: auto;
    height: auto;

}

.swiper-inner {

    flex: 1;
    position: relative;

    img {

        height: 100%;
        object-fit: cover;

    }

    .marquee {

        position: absolute;
        width: 100%;
        left: 0;
        bottom: 2rem;

        @media (width < 960px) {

            bottom: calc(2rem + (960px - 100vw) * 0.1);

        }
    }
}

.swiper-pagination-main {

    position: absolute;
    width: 2.5rem;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (width <= 576px) {

        display: none;

    }

    .swiper-pagination-bullet {

        display: block;
        flex-shrink: 0;
        cursor: pointer;
        width: 1rem;
        height: 1rem;
        border: 0.1rem solid white;
        border-radius: 50%;
        background: rgb(5, 241, 159);
        transform: scale(0.2);
        transition: transform 0.5s ease-out;

        &:hover {

            transform: scale(0.8);
            transition-duration: 0.15s;

        }
    }

    .swiper-pagination-bullet-active-prev,
    .swiper-pagination-bullet-active-next {

        transform: scale(0.6);

    }

    .swiper-pagination-bullet-active-prev-prev,
    .swiper-pagination-bullet-active-next-next {

        transform: scale(0.4);

    }

    .swiper-pagination-bullet-active,
    .swiper-pagination-bullet-active:hover {

        transform: scale(1);

    }
}

.swiper-nested {

    padding: 2.5rem;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    margin: 0;

    &[data-particles] {

        background: rgb(5, 241, 159);

    }

    .swiper-wrapper {

        flex-basis: 65%;
        order: 2;
        display: block;

    }

    .swiper-slide {

        font-family: Overpass;
        line-height: 1.2;

        h2 {

            height: 7rem;
            display: flex;
            align-items: flex-end;
            margin: 0;
            padding-bottom: 1rem;
            text-transform: uppercase;
            font-size: 1rem;
            font-weight: 900;
            letter-spacing: 0.05em;
            color: rgb(30, 54, 255);

        }

        p {

            font-size: 1.5rem;
            font-weight: 300;
            margin: 0;

        }
    }
}

.swiper-pagination-nested {

    height: auto;
    flex-basis: 45%;
    order: 1;
    padding-top: 7rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    font-family: "Playfair Display";
    font-size: 1.2rem;
    line-height: 1.66;
    color: rgb(29, 54, 255);
    transition: transform 0.5s ease-out;

    @for $i from 1 to 3 {

        &[data-current-index="$i"] {

            transform: translateY(calc($i * -2rem));

        }
    }

    .swiper-pagination-bullet {

        display: flex;
        flex-shrink: 0;
        align-items: center;
        height: 2rem;
        cursor: pointer;

        &:after {

            content: "";
            height: 1px;
            flex: 1;
            background: blue;
            margin: 0 0.75rem;
            transform-origin: right center;
            transform: scaleX(0);
            transition: transform 0.5s ease-in-out;

        }
    }

    .swiper-pagination-bullet-active:after {

        transform-origin: left center;
        transform: scaleX(1);

    }
}

.team-overlay {

    font-size: 1rem;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    pointer-events: none;
    background-color: #1a1a1a; 
    z-index: 3;
    transition: opacity 0.8s ease-out;
    will-change: opacity;
    
    @media (width <= 576px) {

        font-size: 12px;

    }
    > img {

        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 150%;
        height: 150%;
        object-fit: cover;
        /* transition: all 0.5s ease-out 0.5s; */
        transition: all 2s cubic-bezier(0, 0, 0.3, 1);
        transform: translateY(calc(-40%));
        will-change: transform;
        opacity: 0;

    }

    &[data-active] { 

        opacity: 1;
        pointer-events: all;
        transition: all 1s ease-out;

        &.mousemove {

            > img {

                @for $i from 1 to 3 {
                    &:nth-child($i) {
                                
                        transition-delay: 0s;
                        transition: all 0s linear;

                    }

                }
            }

        }

        > img {

            transform: translateY(0);
            transition: all 1s cubic-bezier(0, 0, 0.3, 1);

            @for $i from 1 to 3 {
                &:nth-child($i) {
                            
                    transition-delay: calc(($i) * 0.02s);
                    opacity: 1;

                }

            }

        }

    }

}

.team-member {

    position: absolute;
    left: 0;
    top: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 100%;
    grid-gap: 2.5em;
    padding: 2.5em;
    align-items: center;
    pointer-events: none;
    width: 100vw;
    height: 100vh;
    color: white;
    cursor: url("/assets/icons/close.png") 40 40, pointer;
    overflow: auto;

    @media (width <= 864px) {

        display: block;
        cursor: pointer
        
    }

    @media (hover: none) {
        margin-top: 2rem;
    }

    &:before {

        content: "";
        width: 3.8rem;
        height: 4.2rem;
        background-image: url(/assets/icons/close.png);
        position: absolute;
        top: 0rem;
        background-position: 50%;
        background-size: 3rem;
        background-repeat: no-repeat;
        right: 1rem;
        display: none;
        opacity: 0;
        transition: opacity 1s cubic-bezier(0.455, 0.03, 0.515, 0.955);

        @media (hover: none) {

            display: inline-block;
            
        }

    }

    /* Edge & IE quirks */
    > * {

        align-self: center

    }

    &[data-active] {

        pointer-events: all;

        &:after {

            transform: translateY(0%);
            opacity:   1;
        }

        &::before {

            opacity:   1;
        }

        .team-meta > *,
        .team-description {

            transform: none;
            opacity: 1;

        }

        .team-meta > * {

            @for $i from 1 to 2 {

                &:nth-child($i) {

                    transition-delay: calc(($i - 1) * 0.15s);

                }
            }

            > div {

                @for $i from 1 to 2 {

                    &:nth-child($i) {

                        transition-delay: calc(($i - 1) * 0.15s);

                    }

                }

            }

        }

        .team-description {

            transition-delay: 0.45s;

        }
    }

    .team-meta {

        position: relative;
        height: 100%;
        padding: 1em 0;
        display: flex;
        flex-direction: column;

        @media (width <= 864px) and (width > 576px) {
            
            display: grid;
            grid-template-columns: min-content;
            grid-template-areas: "name image"
                                 "title image";
            height: auto;
            align-items: center;
            position: relative;
            justify-content: center;

        }

        @media (width <= 576px) {
            
            padding: 0;
            align-items: center;
            justify-content: flex-end;
            height: auto;
            width: 100%;

        }

        > * {

            transition: all 1s cubic-bezier(0.455, 0.03, 0.515, 0.955);
            transition-property: opacity, transform;
            transition-delay: 0s;
            transform: translateY(-1rem);
            opacity: 0;

        }

        h3 {

            order: 2;
            font-size: 3.5em;
            line-height: 1;
            margin: 0;
            margin-top: 1rem;
            background: none;
            text-transform: none;
            max-width: 100%;
            padding: 0;
            text-align: right;
            color: transparent;
            font-weight: 700; 
            letter-spacing: 0;
            
            text-shadow: 0.045em 0.045em 0 #1e36ff,
                         -0.015em -0.015em 0 #1e36ff,  
                         0.015em -0.015em 0 #1e36ff,
                         -0.015em  0.015em 0 transparent,
                         0.015em  0.015em 0 transparent;

            span {

                display: block;
                font-size: 3.5rem;
                line-height: 1;
                font-weight: 700; 
                letter-spacing: 0;

                @media (width <= 768px) {

                    font-size: 4.5rem;

                }

                &:last-of-type {

                    padding-bottom: 0.3rem;

                }

            }

            @supports(-webkit-text-stroke-color: white) {

                text-shadow: none;

                -webkit-text-stroke-color: #1e36ff;
                -webkit-text-stroke-width: 0.015em;
                -webkit-text-fill-color: transparent;

            }

            @media (width <= 864px) and (width > 576px) {
            
                grid-area: name;

            }

            @media (width <= 576px) {
            
                text-align: center;

                text-shadow: 0.06em 0.06em 0 #1e36ff,
                             -0.02em -0.02em 0 #1e36ff,  
                             0.02em -0.02em 0 #1e36ff,
                             -0.02em  0.02em 0 transparent,
                             0.02em  0.02em 0 transparent;

                @supports(-webkit-text-stroke-color: white) {

                    text-shadow: none;

                    -webkit-text-stroke-width: 0.02em;

                }

            }
        }

        h4 {

            order: 3;
            font-family: Playfair Display,serif;
            font-weight: 400;
            font-style: italic;
            text-align: right;
            margin: 1em 0 1em 5em;

            @media (width <= 768px) {

                font-size: 1.5rem;

            }

            @media (width <= 864px) and (width > 576px) {
            
                grid-area: title;
                margin: 1em 0;

            }

            @media (width <= 576px) {
            
                margin: 1em 0;
                text-align: center;

            }

            &:before {

                content: "";
                display: inline-block;
                vertical-align: middle;
                width: 2em;
                height: 1px;
                background: white;
                margin-right: 1em;

                @media (width <= 576px) {
                
                    display: none;

                }
            }
        }

        img {

            order: 4;
            margin: -10vmin;
            margin-bottom: 0;
            margin-left: auto;
            min-width: 18rem;
            max-width: 13rem;
            mix-blend-mode: lighten;
            pointer-events: none;
            will-change: opacity;

            @media (width <= 864px) and (width > 576px) {

                grid-area: image;
                margin: -4rem 0;

            }

            @media (width <= 576px) {
                
                position: static;
                order: 1;
                margin: -3rem 0;
                height: auto;
                max-height: 21rem;

            }
        }

        > div {

            position: relative;
            order:    4;

        }

        nav {

            order: 5;
            position: absolute;
            right: -5%;
            bottom: -21%;
            display: grid;
            grid-template-rows: auto;
            grid-template-columns: 2rem;
            grid-gap: 1vh;

            @media (width <= 864px) {

                width: 100%;
                right: 0;
                position: static;

            }

            @media (width <= 864px) and (width > 576px) {

                margin: 0;
                grid-template-columns: repeat(auto-fit, 2em);
                justify-content: end;

            }

            @media (width <= 576px) {
                
                grid-template-columns: repeat(auto-fit, 3em);
                grid-gap: 1rem;
                justify-content: center;
                margin-top: 1rem;
                display: inline-flex;

            }

            a {

                width: 2rem;
                height: 2rem;
                display: flex;
                align-items: center;
                max-width: 3rem;
                max-height: 3rem;

                @media (width <= 576px) {
                
                    width: 3rem;
                    height: 3rem;
                    margin: 0 1rem;

                }

                &:hover {

                    svg rect {

                        transform: scaleY(1);

                    }
                }
            }

            svg {

                display: block;
                width: 100%;

                path {

                    stroke: #03ea98;
                    stroke-width: .5;

                }

                rect {

                    fill: #03ea98;
                    transition: transform 0.15s ease-out;
                    transform: scaleY(0);
                    transform-origin: bottom center;

                }
            }
        }
    }

    .team-description {

        height: 100%;
        position: relative;
        transition: all 1s cubic-bezier(0.455, 0.03, 0.515, 0.955);
        transition-property: opacity, transform;
        transition-delay: 0s;
        transform: translateY(-1rem);
        opacity: 0;
        font-size: 0.9rem; 
        line-height: 1.45rem; 
        padding: 2rem 2rem 0;

        -webkit-overflow-scrolling: touch;

        @media (width <= 864px) {

            font-size: 1.4em;
            padding: 0;
            margin-top: 2rem;
            height: auto;

        }

        @media (width <= 576px) {

            line-height: 2rem;

        }

        p {

            line-height: 1.6rem;

            @media (width <= 768px) {

                line-height: 2.6rem;

            }

            &:first-of-type {

                margin-top: 0;

            }

            &:last-of-type {

                margin-bottom: 2rem;

            }

        }
    }
}
.team {

    background-color: white;

    @media (width <= 768px) {

        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;

        @for $nth from 1 to 2 {

            > *:nth-child(2n + $nth) {

                transition-delay: calc(($nth - 1) * 0.15s);

            }
        }
    }

    @media (width > 768px) and (width < 1440px) {

        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: auto;

        @for $nth from 1 to 3 {

            > *:nth-child(3n + $nth) {

                transition-delay: calc(($nth - 1) * 0.15s);

            }
        }
    }

    @media (width >= 1440px) {

        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: auto;

        @for $nth from 1 to 4 {

            > *:nth-child(4n + $nth) {

                transition-delay: calc(($nth - 1) * 0.15s);

            }
        }
    }

    /* img {

        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        mix-blend-mode: lighten;
        will-change: opacity;
        transition: transform 0.5s ease-out;

    } */

    h3 {

        font-size: 0.8rem;
        line-height: 1;
        font-weight: bold;
        margin: 0;
        text-transform: none;
        width: auto;
        padding: 0;
        background: none;
        color: white;
        max-width: 100%;

        @media (width <= 728px) {

            font-size: 1.4rem;
        }

    }

    h4 {

        font-family: 'Playfair Display', serif;
        font-style: italic;
        font-weight: normal;
        margin: 0;
        font-weight: 100;
        font-size: 0.6rem;

        @media (width <= 728px) {

            font-size: 1.1rem;
        }

        &:before {

            content: "- ";

        }
    }

    li {

        overflow: hidden;

    }

    a[data-member] {

        display: block;
        position: relative;
        overflow: hidden;
        color: white;
        flex-direction: column;
        padding: 1rem;
        align-items: baseline;
        text-decoration: none;
        cursor: pointer;
        transition: background-size 0.5s ease-out;
        -moz-transition:  background-size 0.5s ease-out;
        -ms-transition:  background-size 0.5s ease-out;
        -o-transition: background-size 0.5s ease-out;
        -webkit-transition:  background-size 0.5s ease-out;
        will-change: transform;

        /* background-image: url(/assets/images/team/stars.png),
                          url(/assets/images/team/stars-blue.svg),
                          url(/assets/images/team/stars-white.svg),
                          url(/assets/images/team/six-stars.svg),
                          url(/assets/images/team/marc-leclerc.jpg); */
        background-size: 110% 110%,
                         110% 110%,
                         130% 130%,
                         130% 130%,
                         100% 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-color: #1a1a1a;
        min-height: 11rem;

        &:hover {

            background-size: 120% 120%,
                             120% 120%,
                             140% 140%,
                             140% 140%,
                             105% 105%;

        }

        /* &:hover img {

            transform: scale(1.05);

        } */

        @media (width <= 600px) {

            display: flex;
            flex-direction: column;
            transition: none;
            background-size: cover, contain;
            background-position: center center, right center;

        }
    }

    .staircase {

        font-size: 0.7rem;

        @media (width <= 728px) {

            font-size: 1rem;
        }

    }
}
.site-loading {

    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 5;
    pointer-events: none;
    display: grid;

    .blanket {

        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #3a3aff;
        transform: scale(1, 1);
        transform-origin: center bottom;
        transition: all 0.5s cubic-bezier(0.86, 0, 0.7, 1);
        will-change: auto;

    }

    [data-state="ready"] & {

        .blanket {

            transform: scale(1, 0);
            transition: all 0.3s cubic-bezier(0.86, 0, 0.7, 1);

        }

        svg {

            opacity: 0;
            stroke-dashoffset: 0;

        }
    }

    svg {
        justify-self: center;
        align-self: center;
        z-index: 6;
        width: 9rem;
        stroke-dasharray: 900;
        stroke-dashoffset: 900;
        animation: loading .4s cubic-bezier(0.455, 0.03, 0.515, 0.955);

        @media (width <= 480px) {

            width: 15rem;
        }

    }
}

@keyframes loading {

    0% {
       stroke-dashoffset: 900;
    }
    100% {
        stroke-dashoffset: 0;
    }
}
video{

    width: 100%;

    &:focus {

        outline: none;

    }

}

.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
    cursor: pointer;

    &[data-hide] {

        cursor: inherit;

        > div {

            opacity: 0;
            pointer-events: none;

        }

    }

    &:hover {

        > div {

            &:before {

                background-color: rgba(35, 31, 32, 0.60);

            }

        }

    }

    > div {

        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        bottom: 0;
        left: 0;
        background-size: cover;
        background-repeat: no-repeat;
        display: block;
        margin: 0;
        transition: opacity 0.5s ease-in-out 0.5s;

        &:before {

            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 2;
            bottom: 0;
            left: 0;
            background-color: rgba(35, 31, 32, 0.35);
            transition: background-color 0.5s ease-in-out;

        }

        &:after {

            content: "";
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 3;
            width: 34rem;
            height: 24rem;
            background: url("/assets/backgrounds/bouton_jouer.svg") no-repeat;

        }

    }

    + img,
    +.videoWrapper {

        margin-top: 1.1rem;

    }

    iframe,
    video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        pointer-events: all;
    }
}

[data-viewport] {

    transition: all 0.8s ease-in;
    transition-property: transform, opacity;
    will-change: transform, opacity;
    transform: translateY(1rem);
    opacity: 0;

    &[data-direction="-1"] {

        transform: translateY(-1rem);

    }

    &[data-direction="1"] {

        transform: translateY(1rem);

    }

    &[data-viewport="1"] {

        transform: none;
        opacity: 1;

    }
}